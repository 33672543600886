.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid rgb(224, 229, 224);
  &__home {
    > a {
      > button {
        // background-color: red;
        // padding: 0 12px !important;
      }
    }
  }
}
