.sign-up-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //   background-color: yellow;
  .ant-form {
    padding: 0 24px;
    width: 100%;
    max-width: 420px;
    .ant-row {
      //   margin-bottom: 12px;
    }
  }
}
